<template>
  <v-app>
    <v-img
      :class="{
        'v-image--sm': this.$vuetify.breakpoint.smAndDown,
        'v-image--md': this.$vuetify.breakpoint.mdAndDown
      }"
      :src="require(`@/assets/login.jpg`)"
      gradient="to top, rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)"
      min-height="100%"
    >

    </v-img>

    <v-main class="main-bg-full-overlay">
      <v-row>
        <v-col cols="2" md="3" lg="4"></v-col>
        <v-col cols="8" md="6" lg="4" class="pt-6">
          <base-material-card color="indigo" light
            max-width="100%" width="400" class="px-5 py-3">
            <template v-slot:heading>
              <div class="text-center">
                <h1 class="display-2 font-weight-bold mb-2">
                  DDPay Portal
                </h1>
              </div>
            </template>

            <v-card-text class="text-center">
              <!-- <div class="text-center body-1">
                Sign-in
              </div> -->

              <v-form ref="signin-form" lazy-validation
                @submit.native.prevent @submit="signin">

                <v-text-field v-model="form.signin.org" color="secondary"
                  :rules="form.signin.rules.org"
                  :label="$t('views.signin.organization')" prepend-icon="mdi-domain">
                </v-text-field>

                <v-text-field v-model="form.signin.username" color="secondary"
                  :rules="form.signin.rules.username" required
                  :label="$t('views.signin.username')" prepend-icon="mdi-account">
                </v-text-field>

                <v-text-field v-model="form.signin.password" color="secondary"
                  type="password" :rules="form.signin.rules.password" required
                  :label="$t('views.signin.password')" prepend-icon="mdi-lock">
                </v-text-field>

                <v-btn type="submit" block outlined rounded color="indigo" :disabled="isProcessing"
                  class="mt-6">
                  {{ $t('views.signin.buttonSignin') }}
                </v-btn>

                <a @click="ssoLogin">
                  <v-btn outlined rounded color="indigo" :disabled="isProcessing"
                    class="mt-6" style="width: 100%;">
                    {{ $t('views.signin.dotdotCloud') }}
                  </v-btn>
                </a>
              </v-form>

              <div class="text-caption grey--text pt-4 d-flex flex-column">
                <span>Portal v{{ portalVersion }}</span>
                <span>QRPM {{ version.qrpm }}</span>
              </div>

            </v-card-text>

          </base-material-card>
        </v-col>

        <v-col cols="2" md="3" lg="4"></v-col>
      </v-row>
    </v-main>
  </v-app>
</template>

<script>
  import axios from 'axios';

  export default {
    name: 'Signin',

    components: {
      PagesBtn: () => import('./pages/components/Btn'),
    },

    mounted() {
      this.fetchQrpmVersion();
    },

    methods: {
      async ssoLogin() {
        console.log("test")
        const params = new URLSearchParams();
        // params.append('response_type', 'code');
        // params.append('client_id', 'aa2d3727-7368-547b-874f-bb13551eda89');
        // params.append('redirect_uri', '${process.env.VUE_APP_QRPAY_ADMIN_HOST}/auth/dotdot/callback');
        // params.append('scope', 'openid');
        // params.append('state', 'e03=');

        //重定向用户到认证服务器
        window.location = `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/oauth/dotdot?${params.toString()}`;
      },
      signin() {
        if (!this.$refs['signin-form'].validate()) return;
        this.isProcessing = true;

        this.$store.dispatch('requestSignin', {
          organization: this.form.signin.org,
          username: this.form.signin.username,
          password: this.form.signin.password
        })
        .then(user => {
          console.info(`Sign-in succeeded`, user);
          setTimeout(() => {
            this.$router.push('/overview');
          }, 500);
        })
        .catch(err => {
          console.error(`Failed to sign-in.`, err);
          this.$notify({
            text: 'Cannot sign-in. Please check your credentials.',
            type: 'error'
          });
        })
        .finally(() => {
          this.isProcessing = false;
        });
      },

      fetchQrpmVersion() {
        axios.get(`${process.env.VUE_APP_QRPAY_ADMIN_HOST}/version`)
        .then(response => {
          let data = response.data;
          this.version.qrpm = `v${data.major}.${data.minor}.${data.patch}`;
        })
        .catch(err => {
          console.error(`Could not get the version of QRPM?!`, err);
        });
      }
    },

    data() {
      return {
        isProcessing: false,

        form: {
          signin: {
            org: '',
            username: '',
            password: '',

            valid: true,
            rules: {
              org: [
                // v => !!v || 'Organization alias is required',
                // v => (v && v.length >= 2) || 'Organization alias must be at least 2 characters',
              ],
              username: [
                v => !!v || 'Username is required',
                v => (v && v.length >= 4) || 'Username must be no shorter than 4 characters',
              ],
              password: [
                v => !!v || 'Password is required',
                v => (v && v.length >= 8) || 'Password must be no shorter than 8 characters',
              ]
            }
          }
        },

        version: {
          // portal: '',
          qrpm: ''
        }
      }

    },

    computed: {
      styles () {
        const paddingTop = this.$vuetify.breakpoint.mdAndUp ? 175 : 100
        const paddingBottom = this.$vuetify.breakpoint.mdAndUp ? 175 : 150
        return {
          padding: `${paddingTop}px 120px ${paddingBottom}px 0`,
        }
      },

      portalVersion() {
        return process.env.PACKAGE_VERSION;
      }
    },
  }
</script>

<style scoped>
.main-bg-full-overlay {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
}
</style>
